var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"information-company-tabs container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 tabs-row"},[_c('div',{staticClass:"col-tabs",on:{"click":function($event){return _vm.changeActiveTab(1)}}},[_vm._m(0),_vm._v(" "),_c('img',{staticClass:"tabs-image",attrs:{"src":"images/tabs-1.jpg","alt":""}})]),_vm._v(" "),_c('div',{staticClass:"col-tabs",on:{"click":function($event){return _vm.changeActiveTab(2)}}},[_vm._m(1),_vm._v(" "),_c('img',{staticClass:"tabs-image",attrs:{"src":"images/tabs-2.jpg","alt":""}})]),_vm._v(" "),_c('div',{staticClass:"col-tabs",on:{"click":function($event){return _vm.changeActiveTab(3)}}},[_vm._m(2),_vm._v(" "),_c('img',{staticClass:"tabs-image",attrs:{"src":"images/tabs-3.jpg","alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"col-lg-12 tabs-content-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 1),expression:"activeTab === 1"}],staticClass:"col-content-tabs tabs-left",staticStyle:{"display":"none"}},[_vm._m(3)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 2),expression:"activeTab === 2"}],staticClass:"col-content-tabs tabs-center",staticStyle:{"display":"none"}},[_vm._m(4)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 3),expression:"activeTab === 3"}],staticClass:"col-content-tabs tabs-right"},[_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-red-row"},[_c('h3',{staticClass:"tabs-red-title"},[_vm._v("Gift Cards and Bon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-red-row"},[_c('h3',{staticClass:"tabs-red-title"},[_vm._v("Gift sets for all")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-red-row"},[_c('h3',{staticClass:"tabs-red-title"},[_vm._v("Vouchery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-tabs"},[_c('h3',{staticClass:"content-tabs-title"},[_vm._v("\n                        Gift\n                    ")]),_vm._v(" "),_c('p',{staticClass:"content-tabs-text"},[_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni sunt\n                        ullam nemo iste provident, harum aperiam, tenetur hic commodi ex quidem est voluptatibus\n                        officiis rerum, veritatis at! Reiciendis, veritatis obcaecati?")]),_vm._v(" "),_c('div',{staticClass:"content-tabs-btn"},[_c('a',{staticClass:"tabs-btn",attrs:{"href":"#"}},[_vm._v("\n                            Button\n                        ")]),_vm._v(" "),_c('button',{staticClass:"tabs-btn"},[_vm._v("Button")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-tabs"},[_c('h3',{staticClass:"content-tabs-title"},[_vm._v("\n                        Gift\n                    ")]),_vm._v(" "),_c('p',{staticClass:"content-tabs-text"},[_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni sunt\n                        ullam nemo iste provident, harum aperiam, tenetur hic commodi ex quidem est voluptatibus\n                        officiis rerum, veritatis at! Reiciendis, veritatis obcaecati?")]),_vm._v(" "),_c('div',{staticClass:"content-tabs-btn"},[_c('a',{staticClass:"tabs-btn",attrs:{"href":"#"}},[_vm._v("\n                            Button\n                        ")]),_vm._v(" "),_c('button',{staticClass:"tabs-btn"},[_vm._v("Button")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-tabs"},[_c('h3',{staticClass:"content-tabs-title"},[_vm._v("\n                        Gift\n                    ")]),_vm._v(" "),_c('p',{staticClass:"content-tabs-text"},[_vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni sunt\n                        ullam nemo iste provident, harum aperiam, tenetur hic commodi ex quidem est voluptatibus\n                        officiis rerum, veritatis at! Reiciendis, veritatis obcaecati?")]),_vm._v(" "),_c('div',{staticClass:"content-tabs-btn"},[_c('a',{staticClass:"tabs-btn",attrs:{"href":"#"}},[_vm._v("\n                            Button\n                        ")]),_vm._v(" "),_c('button',{staticClass:"tabs-btn"},[_vm._v("Button")])])])
}]

export { render, staticRenderFns }